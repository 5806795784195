import React from "react"
import { Container, Box, Text, Button } from "@chakra-ui/react"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import ImgTextModule from "../ImgTextModule"
import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"

function BannerCareer() {
  return (
    <Container variant="layoutContainer">
      <ImgTextModule
        w="100%"
        px={{ base: 4, md: 12, lg: 4, xl: 0 }}
        src={"shortmaster4.mp4"}
        delay={0.9}
        duration={1}
        threshold={0.4}
        initialY={0}
        initialX={0}
        video={true}
        colStart={1}
        colEnd={3}
        mediaOpacity={0.8}
        bgColor="black"
      >
        <FadeInAnimation threshold={0.4} duration={1}>
          <Box p={8} pt={64}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="white" fontSize="4xl" fontWeight="bold">
                Ein tolles Team erwartet Dich.
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.6}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text mt={4} color="white" fontSize="lg">
                Gutes Arbeitsklima mit einem Team, das den Praxisalltag rockt!
              </Text>
            </FadeInAnimation>

            <RouterLink isExternal={false} link={"/arbeitemituns"}>
              <Button
                mt={4}
                variant="solid"
                bg="brand.violet.medviolet"
                color="brand.white"
              >
                weiter
              </Button>
            </RouterLink>
          </Box>
        </FadeInAnimation>
      </ImgTextModule>
    </Container>
  )
}

export default BannerCareer
